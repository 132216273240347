import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField, Input, Typography } from "@mui/material";
import { addToMenu } from "../../actions/actionCreator";
import { uploadCategoryImage } from "../../actions/menu";
import { SketchPicker } from "react-color";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const initialColor = {
  background: { r: 178, g: 80, b: 104, a: 1 },
};

export default function CategoryModal() {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(initialColor);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const data = new FormData();
    const imageUrl = e.target.files[0];
    setImage(imageUrl.name);
    data.append("categoryImage", imageUrl);
    dispatch(uploadCategoryImage(data));
  };

  const handleCategory = () => {
    const newCategory = {
      id: new Date().getTime(),
      class: "category",
      title: category,
      products: [],
      image,
      color,
    };

    dispatch(addToMenu(newCategory));
    setCategory("");
    handleClose();
  };

  const handleColorChange = (color, event) => {
    color = {
      hex: "#B25068",
      rgb: {
        r: 178,
        g: 80,
        b: 104,
        a: 1,
      },
      hsl: {
        h: 0,
        s: 0,
        l: 0.2,
        a: 1,
      },
    };
  };

  const handleChangeComplete = (color, event) => {
    setColor({ background: color.rgb });
  };

  return (
    <div>
      <Button
        sx={{ fontSize: window.innerWidth > 728 ? "14px" : "11px" }}
        onClick={handleOpen}
      >
        Kategori Ekle
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              onChange={(e) => setCategory(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="category"
              placeholder="Category Name"
              value={category}
            />
            <div
              style={{
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Kategori Rengi</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SketchPicker
                    color={color.background}
                    onChange={handleColorChange}
                    onChangeComplete={handleChangeComplete}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
            <Input
              type="file"
              placeholder="Upload image"
              name="image"
              onChange={handleChange}
            />
          </Box>
          <Button onClick={handleCategory}>Ekle</Button>
        </Box>
      </Modal>
    </div>
  );
}
