import React, { useEffect } from "react";
import { Box } from "@mui/material";
import User from "../components/User";
import { getUsers } from "../actions/auth";
import { useSelector, useDispatch } from "react-redux";

const Users = () => {
  const dispatch = useDispatch();

  const { users, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <Box>
      <Box>Users</Box>
      {users.map((user, i) => (
        <User key={i} user={user} />
      ))}
    </Box>
  );
};

export default Users;
