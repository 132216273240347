import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { TextField, Input, Typography } from "@mui/material";
import { editMenuItem } from "../../actions/actionCreator";
import EditIcon from "@mui/icons-material/Edit";
import { uploadCategoryImage } from "../../actions/menu";
import { SketchPicker } from "react-color";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function CategoryEditModal({ category }) {
  const initialColor = {
    background: {
      r: category.color?.background.r,
      g: category.color?.background.g,
      b: category.color?.background.b,
      a: category.color?.background.a,
    },
  };
  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState(category.title);
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(initialColor);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const data = new FormData();
    const imageUrl = e.target.files[0];
    setImage(imageUrl.name);
    data.append("categoryImage", imageUrl);
    dispatch(uploadCategoryImage(data));
  };

  const handleCategory = () => {
    const editCategory = {
      id: category.id,
      class: "category",
      title: categoryName,
      products: category.products,
      image,
      color,
    };

    dispatch(editMenuItem(editCategory));
    setCategoryName("");
    handleClose();
  };

  const handleColorChange = (color, event) => {
    color = {
      hex: "#B25068",
      rgb: {
        r: 51,
        g: 51,
        b: 51,
        a: 1,
      },
      hsl: {
        h: 0,
        s: 0,
        l: 0.2,
        a: 1,
      },
    };
  };

  const handleChangeComplete = (color, event) => {
    setColor({ background: color.rgb });
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <EditIcon sx={{ color: "white" }} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ marginBottom: "20px" }}>
            <TextField
              onChange={(e) => setCategoryName(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="category"
              placeholder="Category Name"
              value={categoryName}
            />
            <div
              style={{
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Category Color</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SketchPicker
                    color={color.background}
                    onChange={handleColorChange}
                    onChangeComplete={handleChangeComplete}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
            <Input
              type="file"
              placeholder="Upload image"
              name="image"
              onChange={handleChange}
            />
          </Box>
          <Button onClick={handleCategory}>Değiştir</Button>
        </Box>
      </Modal>
    </div>
  );
}
