import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { uploadThumbnail } from "../../actions/menu";

const aspectRatios = [
  { value: 1 / 1, text: "1/1" },
  { value: 4 / 3, text: "4/3" },
  { value: 16 / 9, text: "16/9" },
];

const ImageCropDialog = ({
  id,
  imageUrl,
  cropInit,
  zoomInit,
  aspectInit,
  onCancel,
  setCroppedImageFor,
  resetImage,
  setOpenThumbnailModal,
  url,
}) => {
  if (zoomInit == null) {
    zoomInit = 1;
  }
  if (cropInit == null) {
    cropInit = { x: 0, y: 0 };
  }
  if (aspectInit == null) {
    aspectInit = aspectRatios[0];
  }
  const [zoom, setZoom] = useState(zoomInit);
  const [crop, setCrop] = useState(cropInit);
  const [aspect, setAspect] = useState(aspectInit);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const dispatch = useDispatch();

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onAspectChange = (e) => {
    const value = e.target.value;
    const ratio = aspectRatios.find((ratio) => ratio.value == value);
    setAspect(ratio);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCrop = async () => {
    const croppedImageUrl = await getCroppedImg(url, croppedAreaPixels);
    setCroppedImageFor(id, crop, zoom, aspect, croppedImageUrl);
    setOpenThumbnailModal(false);
  };

  const onResetImage = () => {
    resetImage(url);
    setZoom(zoomInit);
    setAspect(aspectInit);
    setCrop(cropInit);
  };

  return (
    <div>
      <div className="backdrop"></div>
      <div className="crop-container">
        <Cropper
          image={url}
          zoom={zoom}
          crop={crop}
          aspect={aspect.value}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="controls">
        <div className="controls-upper-area">
          <input
            type="range"
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onInput={(e) => {
              onZoomChange(e.target.value);
            }}
            className="slider"
          ></input>
          {
            <select onChange={onAspectChange}>
              {aspectRatios.map((ratio) => (
                <option
                  key={ratio.text}
                  value={ratio.value}
                  selected={ratio.value === aspect.value}
                >
                  {ratio.text}
                </option>
              ))}
            </select>
          }
        </div>
        <div className="button-area">
          <button className="button" onClick={onCancel}>
            İptal
          </button>
          <button className="button" onClick={onResetImage}>
            Reset
          </button>
          <button className="button" onClick={onCrop}>
            Kes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropDialog;
