export const ADD_TO_MENU = "ADD_TO_MENU";
export const DELETE_FROM_MENU = "DELETE_FROM_MENU";
export const EDIT_MENU_ITEM = "EDIT_MENU_ITEM";
export const ADD_PRODUCT_TO_CATEGORY = "ADD_PRODUCT_TO_CATEGORY";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const PRODUCT_CLEANER = "PRODUCT_CLEANER";

export const SIGNIN_LOADING = "SIGNIN_LOADING";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const EDIT_USER_LOADING = "EDIT_USER_LOADING";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";

export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const SAVE_MENU_LOADING = "SAVE_MENU_LOADING";
export const SAVE_MENU_SUCCESS = "SAVE_MENU_SUCCESS";
export const SAVE_MENU_FAIL = "SAVE_MENU_FAIL";

export const GET_MENU_LOADING = "GET_MENU_LOADING";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_FAIL = "GET_MENU_FAIL";

export const UPLOAD_IMAGE_LOADING = "UPLOAD_IMAGE_LOADING";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";

export const UPLOAD_TITLE_IMAGE_LOADING = "UPLOAD_TITLE_IMAGE_LOADING";
export const UPLOAD_TITLE_IMAGE_SUCCESS = "UPLOAD_TITLE_IMAGE_SUCCESS";
export const UPLOAD_TITLE_IMAGE_FAIL = "UPLOAD_TITLE_IMAGE_FAIL";

export const UPLOAD_CATEGORY_IMAGE_LOADING = "UPLOAD_CATEGORY_IMAGE_LOADING";
export const UPLOAD_CATEGORY_IMAGE_SUCCESS = "UPLOAD_CATEGORY_IMAGE_SUCCESS";
export const UPLOAD_CATEGORY_IMAGE_FAIL = "UPLOAD_CATEGORY_IMAGE_FAIL";

export const UPDATE_SORT = "UPDATE_SORT";
export const UPDATE_CAT_SORT = "UPDATE_CAT_SORT";

export const SEND_FEED_IMAGES_LOADING = "SEND_FEED_IMAGES_LOADING";
export const SEND_FEED_IMAGES_SUCCESS = "SEND_FEED_IMAGES_SUCCESS";
export const SEND_FEED_IMAGES_FAIL = "SEND_FEED_IMAGES_FAIL";

export const FETCH_INSTAGRAM_FEED_LOADING = "FETCH_INSTAGRAM_FEED_LOADING";
export const FETCH_INSTAGRAM_FEED_SUCCESS = "FETCH_INSTAGRAM_FEED_SUCCESS";
export const FETCH_INSTAGRAM_FEED_FAIL = "FETCH_INSTAGRAM_FEED_FAIL";

export const ICONS_UPLOAD_LOADING = "ICONS_UPLOAD_LOADING";
export const ICONS_UPLOAD_SUCCESS = "ICONS_UPLOAD_SUCCESS";
export const ICONS_UPLOAD_FAIL = "ICONS_UPLOAD_FAIL";

export const GET_ICONS_LOADING = "GET_ICONS_LOADING";
export const GET_ICONS_SUCCESS = "GET_ICONS_SUCCESS";
export const GET_ICONS_FAIL = "GET_ICONS_FAIL";

export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";

export const UPLOAD_THUMBNAIL_LOADING = "UPLOAD_THUMBNAIL_LOADING";
export const UPLOAD_THUMBNAIL_SUCCESS = "UPLOAD_THUMBNAIL_SUCCESS";
export const UPLOAD_THUMBNAIL_FAIL = "UPLOAD_THUMBNAIL_FAIL";
