import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { TextField, Input } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { editUser, deleteUser } from "../../actions/auth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function UserEditModal({ user }) {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleUser = () => {
    if (newPassword !== confirmPassword) return alert("passwords do not match");

    let userWillEdit = {
      id: user._id,
      firstName,
      lastName,
      email,
    };

    if (newPassword && confirmPassword) {
      userWillEdit = {
        ...userWillEdit,
        password: newPassword,
      };
    }

    dispatch(editUser(userWillEdit));
    handleClose();
  };

  const handleDeleteUser = () => {
    dispatch(deleteUser(user));
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <EditIcon sx={{ color: "white" }} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ marginBottom: "20px" }}>
            <TextField
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="firtname"
              placeholder="First Name"
              defaultValue={firstName}
            />
            <TextField
              onChange={(e) => setLastName(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="lastname"
              placeholder="Last Name"
              defaultValue={lastName}
            />
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="email"
              placeholder="Email"
              defaultValue={email}
            />
            <TextField
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="newpassword"
              placeholder="New Password"
              defaultValue={newPassword}
            />
            <TextField
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="confirmpassword"
              placeholder="Confirm Password"
              defaultValue={confirmPassword}
            />
          </Box>
          <Button onClick={handleUser}>Save</Button>
          <Button onClick={handleDeleteUser} sx={{ color: "red" }}>
            Delete
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
