import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  feedImages: [],
  fetchFeed: [],
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SEND_FEED_IMAGES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEND_FEED_IMAGES_SUCCESS:
      return {
        ...state,
        feedImages: action.payload.images.map((item, i) => {
          return { original: item.media_url, thumbnail: item.media_url };
        }),
        loading: false,
      };
    case actionTypes.SEND_FEED_IMAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_INSTAGRAM_FEED_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_INSTAGRAM_FEED_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchFeed: action.payload.map((item, i) => {
          return { original: item.media_url, thumbnail: item.media_url };
        }),
      };
    case actionTypes.FETCH_INSTAGRAM_FEED_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
