import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  menu: [],
  loading: false,
  error: null,
  titleImage: "",
  feedImages: [],
  toggle: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_MENU:
      return {
        ...state,
        menu: [...state.menu, action.payload],
      };
    case actionTypes.ADD_PRODUCT_TO_CATEGORY:
      return {
        ...state,
        menu: action.payload,
      };
    case actionTypes.DELETE_FROM_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case actionTypes.EDIT_MENU_ITEM:
      return {
        ...state,
        menu: state.menu.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      };
    case actionTypes.EDIT_PRODUCT:
      return {
        ...state,
        menu: state.menu.map((item) => {
          if (item.id === action.payload.categoryId) {
            item.products = item.products.map((product) => {
              if (product.id === action.payload.newProduct.id) {
                return action.payload.newProduct;
              }
              return product;
            });
          }
          return item;
        }),
      };
    case actionTypes.SAVE_MENU_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SAVE_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        menu: action.payload.menu,
      };
    case actionTypes.SAVE_MENU_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_MENU_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        menu: action.payload.menu,
        error: null,
      };
    case actionTypes.GET_MENU_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPLOAD_IMAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        menu: state.menu.map((item, index) => {
          if (item.id === action.payload.category.id) {
            item.products = item.products.map((product, i) => {
              if (product.id === action.payload.productId) {
                product.image = action.payload.image;
                return product;
              } else {
                return product;
              }
            });
          }
          return item;
        }),
      };
    case actionTypes.UPLOAD_TITLE_IMAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_TITLE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        titleImage: action.payload,
      };
    case actionTypes.UPLOAD_TITLE_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPLOAD_CATEGORY_IMAGE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.UPLOAD_CATEGORY_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPLOAD_CATEGORY_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_SORT:
      return {
        ...state,
        menu: state.menu.map((item, i) => {
          if (item.id === action.payload.id) {
            item.products = action.payload.products;
          }
          return item;
        }),
      };
    case actionTypes.UPDATE_CAT_SORT:
      return {
        ...state,
        menu: action.payload,
      };
    case actionTypes.TOGGLE_SIDE_MENU:
      return {
        ...state,
        toggle: action.payload,
      };
    default:
      return state;
  }
};
