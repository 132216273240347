import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CardMedia from "@mui/material/CardMedia";
import { uploadIcons, getIcons } from "../actions/menu";
import { TextField, Input } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function Icons() {
  const dispatch = useDispatch();
  const { icons } = useSelector((state) => state.icons);
  const [iconsImg, setIconsImg] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const iconsImg = e.target.files;
    setIconsImg(iconsImg);
  };

  const handleSubmit = async () => {
    const data = new FormData();

    for (let i = 0; i < iconsImg.length; i++) {
      data.append("icons", iconsImg[i]);
    }

    dispatch(uploadIcons(data));
    handleClose();
  };

  const renderIcons = () => {
    const items = icons.map((item, i) => (
      <CardMedia
        key={i}
        sx={{
          width: "30px",
          height: "30px",
          objectFit: "fill",
          margin: "5px",
        }}
        component="img"
        image={`imagePathicons/${item}`}
        //alt={`${item.filename}`}
      />
    ));
    return items;
  };

  /*  useEffect(() => {
    dispatch(getIcons());
  }, []); */

  return (
    <div>
      <Button
        sx={{ fontSize: window.innerWidth > 728 ? "14px" : "11px" }}
        onClick={handleOpen}
      >
        İkonlar
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ marginBottom: "20px" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "35px",
              }}
            >
              {renderIcons()}
            </Box>
            <input
              multiple
              type="file"
              placeholder="Upload image"
              name="image"
              onChange={handleChange}
            />
          </Box>
          <Button onClick={handleSubmit}>Upload</Button>
        </Box>
      </Modal>
    </div>
  );
}
