import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import MainTitle from "../components/MainTitle";
import Category from "../components/Category";
//import MainTitleModal from "../components/modals/MainTitleModal";
import CategoryModal from "../components/modals/CategoryModal";
import Icons from "../components/Icons";
import { saveMenu, getMenu } from "../actions/menu";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { fetchInstagramFeed } from "../actions/menu";
import { updateCatSort } from "../actions/actionCreator";
import { getIcons } from "../actions/menu";
import CircularProgress from "@mui/material/CircularProgress";

import "alertifyjs/build/css/alertify.css";

const Main = () => {
  const dispatch = useDispatch();
  const [lang, setLang] = useState("Tr");
  const { menu, loading } = useSelector((state) => state.menu);
  const { icons } = useSelector((state) => state.icons);
  const { fetchFeed } = useSelector((state) => state.feedImages);
  const [sort, setSort] = useState(false);
  const [sortableCat, setSortableCat] = useState([]);

  const sortAlert = () => {
    alert("Enable sort before using it");
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(menu);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSortableCat(items);
    dispatch(updateCatSort(items));
  }

  /* const handleMenuList = (data) => {
    setLang(data);
  }; */

  const handleIsSorting = () => {
    setSort(!sort);
  };

  const renderInstaFeed = () => {
    return menu.map((item, i) => {
      if (item.class === "main-title") {
        return <MainTitle key={i} info={item} feed={fetchFeed} />;
      }
    });
  };
  const renderList = () => {
    return menu.map((item, i) => {
      if (item.class === "category") {
        return (
          <Draggable key={i} draggableId={i.toString()} index={i}>
            {(provided) => (
              <Box
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                key={i.toString()}
              >
                <Category key={i} category={item} sort={sort} />
              </Box>
            )}
          </Draggable>
        );
      }
    });
  };

  const handleSaveMenu = () => {
    const menuData = {
      menu,
      lang,
    };
    dispatch(saveMenu(menuData));
  };

  useEffect(() => {
    dispatch(getMenu({ lang }));
  }, [lang]);

  useEffect(() => {
    setSortableCat(menu);
  }, [menu]);

  useEffect(() => {
    dispatch(fetchInstagramFeed());
    dispatch(getIcons());
  }, []);

  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <Box sx={{ width: "1000px", margin: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <Box sx={{ display: "flex", width: "50%" }}>
            {/* <MainTitleModal /> */}
            <CategoryModal />
            <Button
              sx={{ fontSize: window.innerWidth > 728 ? "14px" : "11px" }}
              onClick={handleIsSorting}
            >
              {!sort ? "Sıralamayı Aç" : "Sıralamayı Kapat"}
            </Button>
            <Icons />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "50%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* <Button onClick={() => handleMenuList("Tr")}>TR</Button>
            <Button onClick={() => handleMenuList("En")}>EN</Button> */}

            {/*   {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                sx={{ backgroundColor: "teal", color: "white" }}
                onClick={handleSaveMenu}
              >
                Kaydet
              </Button>
            )} */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "teal",
                color: "white",
                height: "40px",
                width: "80px",
              }}
              onClick={handleSaveMenu}
            >
              {loading ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                "Kaydet"
              )}
            </Button>
          </Box>
        </Box>
        <DragDropContext onDragEnd={sort ? handleOnDragEnd : sortAlert}>
          <Droppable droppableId="products">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {renderInstaFeed()}
                {renderList()}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default Main;
