import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  thumbnailImage: null,
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_THUMBNAIL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_THUMBNAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        thumbnailImage: action.payload,
      };
    case actionTypes.UPLOAD_THUMBNAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
