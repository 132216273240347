import * as actions from "./actionCreator";
import axios from "axios";
import alertify from "alertifyjs";

export const signin = (formData, navigate) => async (dispatch) => {
  const data = JSON.stringify(formData);
  dispatch(actions.signinLoading());
  try {
    await axios({
      method: "POST",
      url: `http://test.nodejs.canavardata.com/login`,
      data,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        dispatch(actions.signinSuccess(res.data));
        localStorage.setItem("profile", JSON.stringify({ ...res.data }));
        navigate("/panel");
      })
      .catch((err) => {
        dispatch(actions.signinFail(err.message));
        alertify.error("Geçersiz Giriş");
      });
  } catch (error) {
    dispatch(actions.signinFail(error));
  }
};

export const signup = (formData, navigate) => async (dispatch) => {
  dispatch(actions.signupLoading());

  const data = JSON.stringify(formData);

  try {
    await axios({
      method: "POST",
      url: `http://test.nodejs.canavardata.com/panel/register`,
      data,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        dispatch(actions.signupSuccess(res.data));
        alertify.success("Kayıt başarılı");
        navigate("/panel/users");
      })
      .catch((err) => {
        dispatch(actions.signupFail(err.response.data.msg));
        alertify.error("Kayıt başarısız");
      });
  } catch (error) {
    dispatch(actions.signupFail(error));
  }
};

export const getUsers = () => async (dispatch) => {
  dispatch(actions.getUsersLoading());
  try {
    await axios({
      method: "GET",
      url: `http://test.nodejs.canavardata.com/users/getusers`,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        dispatch(actions.getUsersSuccess(res.data));
      })
      .catch((err) => {
        dispatch(actions.getUsersFail(err.message));
      });
  } catch (error) {
    dispatch(actions.getUsersFail(error.message));
  }
};

export const deleteUser = (userData) => async (dispatch) => {
  const data = JSON.stringify(userData);
  dispatch(actions.deleteUserLoading());
  try {
    await axios({
      method: "DELETE",
      url: `http://test.nodejs.canavardata.com/panel/users/deleteuser`,
      data,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "DELETE",
      },
    })
      .then((res) => {
        dispatch(actions.deleteUserSuccess(res.data));
        alertify.success("Kullanıcı silindi");
      })
      .catch((err) => {
        dispatch(actions.deleteUserFail(err.message));
        alertify.error("Kullanıcı silinemedi");
      });
  } catch (error) {
    dispatch(actions.deleteUserFail(error.message));
  }
};

export const editUser = (userData, navigate) => async (dispatch) => {
  const data = JSON.stringify(userData);
  dispatch(actions.editUserLoading());
  try {
    await axios({
      method: "PUT",
      url: `http://test.nodejs.canavardata.com/panel/users/edituser`,
      data,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        dispatch(actions.editUserSuccess(res.data));
        alertify.success("Kullanıcı güncellendi");
      })
      .catch((err) => {
        dispatch(actions.editUserFail(err.message));
        alertify.error(err.message);
      });
  } catch (error) {
    dispatch(actions.editUserFail(error.message));
  }
};
