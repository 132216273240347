import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import UserEditModal from "./modals/UserEditModal";

const User = ({ user }) => {
  const currentUser = JSON.parse(localStorage.getItem("profile"));

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "60px",
        backgroundColor: "teal",
        marginTop: "12px",
        padding: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "95%",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px",
          flexWrap: "wrap",
          color: "white",
          fontSize: "12px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box sx={{ fontWeight: "bold" }}>AD : </Box>
          <Box>{user.firstName}</Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box sx={{ fontWeight: "bold" }}>SOYAD : </Box>
          <Box>{user.lastName}</Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          <Box sx={{ fontWeight: "bold" }}>EMAIL : </Box>
          <Box>{user.email}</Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "5%",
          flexDirection: "column",
        }}
      >
        {currentUser.result.role ? <UserEditModal user={user} /> : null}
      </Box>
    </Box>
  );
};

export default User;
