import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { TextField, Input, CardMedia } from "@mui/material";
import { addProductToMenu } from "../../actions/actionCreator";
import { uploadImage, uploadThumbnail } from "../../actions/menu";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ImageCropDialog from "./ImageCropDialog";
import { upload } from "@testing-library/user-event/dist/upload";
import { editProduct } from "../../actions/actionCreator";
import EditIcon from "@mui/icons-material/Edit";
import { ColorRing } from "react-loader-spinner";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "85%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const initData = [
  {
    id: 1,
    imageUrl: "13.jpg",
    croppedImageUrl: null,
  },
];

export default function AddProductToCategoryModal({ category, product }) {
  const dispatch = useDispatch();
  const { menu } = useSelector((state) => state.menu);
  const { icons } = useSelector((state) => state.icons);
  const [name, setName] = useState(product.name);
  const [price, setPrice] = useState(product.price);
  const [desc, setDesc] = useState(product.desc);
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [iconNames, setIconNames] = React.useState([]);
  const [thumbnail, setThumbnail] = useState(product.thumbnail);
  const [openThumbnailModal, setOpenThumbnailModal] = useState(false);
  const [url, setUrl] = useState();
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedBlob, setCroppedBlob] = useState(product.thumbnail || null);
  const [preLoader, setPreloader] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [cars, setCars] = useState(initData);
  const [selectedCar, setSelectedCar] = useState(null);

  const onCancel = () => {
    setOpenThumbnailModal(false);
  };

  const setCroppedImageFor = (id, crop, zoom, aspect, croppedImageUrl) => {
    var file = new File([croppedImageUrl.file], croppedImageUrl.file.name);
    setThumbnail(croppedImageUrl.file);
    //const data = new FormData();
    //data.append("thumbnail", file);
    //setImageDataToUpload(data);
    croppedFile(file);
    setCroppedImage(file);
  };

  const resetImage = (url) => {
    setCroppedBlob(url);
  };

  const croppedFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setCroppedBlob(reader.result);
    });
    reader.readAsDataURL(file);
  };

  const uploader = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setUrl(reader.result);
    });
    reader.readAsDataURL(file);
  };

  const handleChange = (e) => {
    setUrl(null);
    setCroppedBlob(null);
    setOpenThumbnailModal(true);
    setThumbnail(image);
    const imageUrl = e.target.files[0];
    setImage(imageUrl);
    uploader(imageUrl);
  };

  const handleIconChange = (event) => {
    const {
      target: { value },
    } = event;
    setIconNames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSave = () => {
    setPreloader(true);
    const categoryId = category.id;
    const newProduct = {
      id: product.id,
      name,
      price,
      desc,
      image,
      icons: iconNames,
      thumbnail: image
        ? `http://test.nodejs.canavardata.com/images/thumbnails/${thumbnail.name}`
        : product.thumbnail,
    };

    const productObject = {
      categoryId,
      newProduct,
    };

    const data = new FormData();
    const thumbData = new FormData();
    data.append("productImage", image);
    thumbData.append("thumbnail", croppedImage);
    data.append("cat", JSON.stringify(category));
    data.append("productId", JSON.stringify(id));

    dispatch(uploadImage(data));
    dispatch(uploadThumbnail(thumbData));

    setTimeout(() => {
      dispatch(editProduct(productObject));
      setPreloader(false);
      handleClose();
    }, 5000);
    //setName("");
    //setPrice(0);
    //setDesc("");
    // setCroppedBlob(null);
    //setUrl(null);
    // handleClose();
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <EditIcon sx={{ fontSize: "16px" }} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ marginBottom: "20px" }}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="name"
              placeholder="Product Name"
              value={name}
            />
            <TextField
              onChange={(e) => setDesc(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="desc"
              placeholder="Product Description"
              value={desc}
            />
            <TextField
              onChange={(e) => setPrice(e.target.value)}
              sx={{ width: "100%", marginBottom: "15px" }}
              name="price"
              placeholder="Product Price"
              value={price}
            />

            <div>
              <FormControl
                sx={{
                  m: 1,
                  width: "100%",
                  margin: "0px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel id="demo-multiple-checkbox-label">Icons</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={iconNames}
                  onChange={handleIconChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {icons.map((icon, i) => (
                    <MenuItem key={i} value={icon}>
                      <Checkbox checked={iconNames.indexOf(icon) > -1} />
                      <ListItemText primary={icon} />
                      <CardMedia
                        key={i}
                        sx={{
                          width: "30px",
                          height: "30px",
                          objectFit: "fill",
                          margin: "5px",
                        }}
                        component="img"
                        image={`http://test.nodejs.canavardata.com/images/icons/${icon}`}
                        //alt={`${item.filename}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Input
                type="file"
                placeholder="Upload image"
                name="image"
                onChange={handleChange}
              />
              {preLoader ? (
                <ColorRing
                  height="300"
                  width="100%"
                  color="#4fa94d"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <CardMedia
                  onClick={() => {
                    setOpenThumbnailModal(true);
                    setThumbnail(image);
                  }}
                  sx={{
                    width: "400px",
                    //height: "30px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    cursor: "pointer",
                    pointerEvents: "none",
                  }}
                  component="img"
                  image={
                    croppedBlob ||
                    url ||
                    `http://test.nodejs.canavardata.com/${product.image}`
                  }
                />
              )}
              {openThumbnailModal ? (
                <ImageCropDialog
                  id={thumbnail.id}
                  imageUrl={image}
                  cropInit={thumbnail.crop}
                  zoomInit={thumbnail.zoom}
                  aspectInit={thumbnail.aspect}
                  onCancel={onCancel}
                  setCroppedImageFor={setCroppedImageFor}
                  resetImage={resetImage}
                  setOpenThumbnailModal={setOpenThumbnailModal}
                  url={url}
                />
              ) : null}
              <Button type="submit" onClick={handleSave}>
                Kaydet
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
