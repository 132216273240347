import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  loading: false,
  user: {},
  users: [],
  token: null,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, action.payload],
      };
    case actionTypes.SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.SIGNIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.result,
        token: action.payload.token,
      };
    case actionTypes.SIGNIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case actionTypes.GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter((user) => user._id !== action.payload._id),
      };
    case actionTypes.DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.EDIT_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.map((user) => {
          if (user._id === action.payload._id) {
            return action.payload;
          }
          return user;
        }),
      };
    default:
      return state;
  }
};
