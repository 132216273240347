import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-scroll";
import { toggleSideMenu } from "../actions/actionCreator";
import { WindowOutlined } from "@mui/icons-material";

export default function SideMenu({ menu }) {
  const dispatch = useDispatch();
  const ref = useRef();
  const { toggle } = useSelector((state) => state.menu);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  /*  const renderList = async () => {
    const listItems = await menu.map((item, i) => {
      return (
        <ListItem key={i} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              {i % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItemButton>
        </ListItem>
      );
    });
    console.log(listItems);
    return listItems;
  }; */

  const toggleAndScroll = (item) => {
    dispatch(toggleSideMenu(item.id));
    /*  setTimeout(() => {
      ref.current?.scrollIntoView({ alignToTop: true, behavior: "smooth" });
    }, 500); */
  };

  const list = (anchor) => (
    <Box
      // sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menu.map((item, i) => {
          if (item.title) {
            return (
              <Link
                onClick={toggleDrawer(anchor, false)}
                to={item.id.toString()}
                spy={true}
                smooth={true}
                offset={500}
                duration={200}
                key={i}
              >
                <ListItem
                  onClick={() => toggleAndScroll(item)}
                  id={item.id.toString()}
                  disablePadding
                >
                  <ListItemButton>
                    {/* <ListItemIcon></ListItemIcon> */}
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Link>
            );
          } else {
            return null;
          }
        })}
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button sx={{ color: "white" }} onClick={toggleDrawer(anchor, true)}>
            {<MenuIcon />}
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
