import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Button } from "@mui/material";
import { deleteFromMenu } from "../actions/actionCreator";
import EditProductModal from "./modals/EditProductModal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Product = ({ category, product, i, sort }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { menu } = useSelector((state) => state.menu);
  // const { thumbnailImage, loading } = useSelector((state) => state.thumbnails);

  const handleDelete = () => {
    const newMenu = [...menu];
    const index = newMenu.findIndex((item) => item.id === category.id);
    newMenu[index].products.map((item) => {
      if (item.id === product.id) {
        newMenu[index].products.splice(i, 1);
      }
    });
    dispatch(deleteFromMenu(newMenu));
  };

  //Tuğba <3

  const submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  return (
    <Card
      elevation={0}
      sx={{
        marginTop: "5px",
        marginBottom: "5px",
        backgroundColor: "#fbfbfb",
        minHeight: "400px",
        width:
          location.pathname === "/panel" ? (sort ? "200px" : "200px") : "100%",
      }}
    >
      <CardActionArea>
        <CardMedia
          sx={{
            width: "100%",
            //maxWidth: "200px",
            height: location.pathname === "/panel" ? "200px" : "170px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            objectFit: "fill",
          }}
          component="img"
          image={
            product.thumbnail
              ? product.thumbnail
              : `http://test.nodejs.canavardata.com/images/${product.image}`
          }
        />

        <CardContent sx={{ textAlign: "start", minHeight: "125px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "18px",
            }}
            gutterBottom
            variant="h6"
            component="div"
          >
            {product.name}
          </Typography>
          <Typography
            gutterBottom
            component="div"
            variant="body2"
            color="text.secondary"
            sx={{
              maxWidth: "100px",
              fontFamily: "Montserrat",
              fontSize: "12px",
            }}
          >
            {product.desc}
          </Typography>
          <Typography
            sx={{
              marginTop: "10px",
              fontFamily: "Montserrat",
              fontSize: "12px",
            }}
          >
            {product.price.substring(0, 32) + "..."}
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              marginTop: "10px",
            }}
          >
            {product.icons &&
              product.icons.map((icon, i) => (
                <CardMedia
                  key={i}
                  sx={{
                    width: "30px",
                    height: "30px",
                    objectFit: "fill",
                    marginRight: "5px",
                    marginBottom: "5px",
                  }}
                  component="img"
                  image={`http://test.nodejs.canavardata.com/images/icons/${icon}`}
                />
              ))}
          </Box>
        </CardContent>
      </CardActionArea>
      {location.pathname === "/panel" && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {location.pathname === "/panel" && sort ? (
            <DragIndicatorIcon />
          ) : null}
          <Box sx={{ display: "flex" }}>
            <EditProductModal product={product} category={category} />
            <Button onClick={submit}>
              <DeleteIcon
                sx={{ fontSize: "16px", width: "20px", color: "red" }}
              />
            </Button>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default Product;
