import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Panel from "./pages/Panel";
import Home from "./pages/Home";
import "./App.css";

function App() {
  const { user } = useSelector((state) => state.auth);
  const token = JSON.parse(localStorage.getItem("profile"))?.token;

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/login"
        element={token ? <Navigate to="/panel" /> : <Login />}
      />
      <Route path="*" element={token ? <Panel /> : <Navigate to="login" />} />
    </Routes>
  );
}

export default App;
