import React, { useState, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/system";
import { CardMedia, Button } from "@mui/material";
import { deleteFromMenu } from "../actions/actionCreator";
import { sendFeedImages } from "../actions/menu";
import MainTitleEditModal from "./modals/MainTitleEditModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import ImageGallery from "react-image-gallery";
//import PlayPause from "react-image-gallery";
import axios from "axios";

const MainTitle = ({ feed, info, token, ...props }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { menu } = useSelector((state) => state.menu);
  const { feedImages, error } = useSelector((state) => state.feedImages);
  //const [images, setImages] = useState([]);
  const [feeds, setFeedsData] = useState([]);
  const playRef = useRef(null);
  const tokenProp = useRef(token);
  tokenProp.current = token;

  window.addEventListener(
    "contextmenu",
    function (e) {
      e.preventDefault();
    },
    false
  );

  const instaToken =
    "IGQVJYMkVwNF9yMzBiTG4wQmxFYnNXM2FiV2JfVUpISWMwakV6MnV1NlJLcnZAYLXN5VU9EazVnVzVudG9rVGo2T29pODZAteFFXWF91U3dLbUQwZA1AyVjRxb0Y2a2NMOUJIZAWZApeFN5OC0xRm1yNG03TwZDZD";

  const limit = 3;

  const [isPlaying, setIsPlaying] = useState(true);

  const handleDelete = () => {
    const newMenu = [...menu];
    const index = newMenu.findIndex((item) => item.id === info.id);
    newMenu.splice(index, 1);
    dispatch(deleteFromMenu(newMenu));
  };

  const playOrPause = (e) => {
    setIsPlaying((prev) => {
      if (playRef) {
        playRef.current[prev ? "pause" : "play"]();
      }
      return !prev;
    });
  };

  /*  useEffect(() => {
    setImages(info.image);
  }, [menu]); */

  useLayoutEffect(() => {
    const abortController = new AbortController();

    async function fetchInstagramPost() {
      try {
        axios
          .get(
            `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${limit}&access_token=${instaToken}`
          )
          .then((resp) => {
            setFeedsData(() => {
              return resp.data.data.map((item, i) => {
                return { original: item.media_url, thumbnail: item.media_url };
              });
            });
            // dispatch(sendFeedImages(resp.data.data));
          });
      } catch (err) {
        console.log("error", err);
      }
    }

    fetchInstagramPost();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Box
      sx={{
        marginBottom: "22px",
        textAlign: "center",
        display:
          location.pathname === "/panel"
            ? "block"
            : window.innerWidth > 728
            ? "none"
            : "block",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: location.pathname === "/panel" ? "30%" : "100%",
            height: location.pathname === "/panel" ? "40%" : "100%",
          }}
        >
          <ImageGallery
            ref={playRef}
            items={feeds || []}
            infinite
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            autoPlay={true}
            slideDuration={1000}
            onTouchStart={playOrPause}
            onTouchEnd={playOrPause}
          />
        </Box>
      </Box>
      {location.pathname === "/panel" && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/*  <MainTitleEditModal info={info} />
          <Button onClick={handleDelete}>
          <DeleteIcon sx={{ color: "red" }} />
        </Button> */}
        </Box>
      )}
    </Box>
  );
};

export default MainTitle;
