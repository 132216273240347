import * as actionTypes from "./actionTypes";

export const addToMenu = (menuItem) => ({
  type: actionTypes.ADD_TO_MENU,
  payload: menuItem,
});

export const deleteFromMenu = (menuItem) => ({
  type: actionTypes.DELETE_FROM_MENU,
  payload: menuItem,
});

export const editMenuItem = (menuItem) => ({
  type: actionTypes.EDIT_MENU_ITEM,
  payload: menuItem,
});

export const addProductToMenu = (product) => ({
  type: actionTypes.ADD_PRODUCT_TO_CATEGORY,
  payload: product,
});

export const editProduct = (product) => ({
  type: actionTypes.EDIT_PRODUCT,
  payload: product,
});

export const productCleaner = () => ({
  type: actionTypes.PRODUCT_CLEANER,
});

export const signinLoading = () => ({
  type: actionTypes.SIGNIN_LOADING,
});

export const signinSuccess = (data) => ({
  type: actionTypes.SIGNIN_SUCCESS,
  payload: data,
});

export const signinFail = (err) => ({
  type: actionTypes.SIGNIN_FAIL,
  payload: err,
});

export const signupLoading = () => ({
  type: actionTypes.SIGNUP_LOADING,
});

export const signupSuccess = (data) => ({
  type: actionTypes.SIGNUP_SUCCESS,
  payload: data,
});

export const signupFail = (err) => ({
  type: actionTypes.SIGNUP_FAIL,
  payload: err,
});

export const getUsersLoading = () => ({
  type: actionTypes.GET_USERS_LOADING,
});

export const getUsersSuccess = (data) => ({
  type: actionTypes.GET_USERS_SUCCESS,
  payload: data,
});

export const getUsersFail = (err) => ({
  type: actionTypes.GET_USERS_FAIL,
  payload: err,
});

export const editUserLoading = () => ({
  type: actionTypes.EDIT_USER_LOADING,
});

export const editUserSuccess = (data) => ({
  type: actionTypes.EDIT_USER_SUCCESS,
  payload: data,
});

export const editUserFail = (err) => ({
  type: actionTypes.EDIT_USER_FAIL,
  payload: err,
});

export const deleteUserLoading = () => ({
  type: actionTypes.DELETE_USER_LOADING,
});

export const deleteUserSuccess = (data) => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  payload: data,
});

export const deleteUserFail = (err) => ({
  type: actionTypes.DELETE_USER_FAIL,
  payload: err,
});

export const saveMenuLoading = () => ({
  type: actionTypes.SAVE_MENU_LOADING,
});

export const saveMenuSuccess = (data) => ({
  type: actionTypes.SAVE_MENU_SUCCESS,
  payload: data,
});

export const saveMenuFail = (err) => ({
  type: actionTypes.SAVE_MENU_FAIL,
  payload: err,
});

export const getMenuLoading = () => ({
  type: actionTypes.GET_MENU_LOADING,
});

export const getMenuSuccess = (data) => ({
  type: actionTypes.GET_MENU_SUCCESS,
  payload: data,
});

export const getMenuFail = (err) => ({
  type: actionTypes.GET_MENU_FAIL,
  payload: err,
});

export const uploadImageLoading = () => ({
  type: actionTypes.UPLOAD_IMAGE_LOADING,
});

export const uploadImageSuccess = (data) => ({
  type: actionTypes.UPLOAD_IMAGE_SUCCESS,
  payload: data,
});

export const uploadImageFail = (err) => ({
  type: actionTypes.UPLOAD_IMAGE_FAIL,
  payload: err,
});

export const uploadTitleImageLoading = () => ({
  type: actionTypes.UPLOAD_TITLE_IMAGE_LOADING,
});

export const uploadTitleImageSuccess = (data) => ({
  type: actionTypes.UPLOAD_TITLE_IMAGE_SUCCESS,
  payload: data,
});

export const uploadTitleImageFail = (err) => ({
  type: actionTypes.UPLOAD_TITLE_IMAGE_FAIL,
  payload: err,
});

export const uploadCategoryImageLoading = () => ({
  type: actionTypes.UPLOAD_CATEGORY_IMAGE_LOADING,
});

export const uploadCategoryImageSuccess = (data) => ({
  type: actionTypes.UPLOAD_CATEGORY_IMAGE_SUCCESS,
  payload: data,
});

export const uploadCategoryImageFail = (err) => ({
  type: actionTypes.UPLOAD_CATEGORY_IMAGE_FAIL,
  payload: err,
});

export const updateSortableProducts = (data) => ({
  type: actionTypes.UPDATE_SORT,
  payload: data,
});

export const updateCatSort = (data) => ({
  type: actionTypes.UPDATE_CAT_SORT,
  payload: data,
});

export const sendFeedImagesLoading = () => ({
  type: actionTypes.SEND_FEED_IMAGES_LOADING,
});

export const sendFeedImagesSuccess = (data) => ({
  type: actionTypes.SEND_FEED_IMAGES_SUCCESS,
  payload: data,
});

export const sendFeedImagesFail = (data) => ({
  type: actionTypes.SEND_FEED_IMAGES_FAIL,
  payload: data,
});

export const fetchInstagramFeedLoading = () => ({
  type: actionTypes.FETCH_INSTAGRAM_FEED_LOADING,
});

export const fetchInstagramFeedSuccess = (data) => ({
  type: actionTypes.FETCH_INSTAGRAM_FEED_SUCCESS,
  payload: data,
});

export const fetchInstagramFeedFail = (data) => ({
  type: actionTypes.FETCH_INSTAGRAM_FEED_FAIL,
  payload: data,
});

export const iconsUploadLoading = () => ({
  type: actionTypes.ICONS_UPLOAD_LOADING,
});

export const iconsUploadSuccess = (data) => ({
  type: actionTypes.ICONS_UPLOAD_SUCCESS,
  payload: data,
});

export const iconsUploadFail = (err) => ({
  type: actionTypes.ICONS_UPLOAD_FAIL,
  payload: err,
});

export const getIconsLoading = () => ({
  type: actionTypes.GET_ICONS_LOADING,
});

export const getIconsSuccess = (data) => ({
  type: actionTypes.GET_ICONS_SUCCESS,
  payload: data,
});

export const getIconsFail = (err) => ({
  type: actionTypes.GET_ICONS_FAIL,
  payload: err,
});

export const toggleSideMenu = (data) => ({
  type: actionTypes.TOGGLE_SIDE_MENU,
  payload: data,
});

export const uploadThumbnailLoading = () => ({
  type: actionTypes.UPLOAD_THUMBNAIL_LOADING,
});

export const uploadThumbnailSuccess = (data) => ({
  type: actionTypes.UPLOAD_THUMBNAIL_SUCCESS,
  payload: data,
});

export const uploadThumbnailFail = (err) => ({
  type: actionTypes.UPLOAD_THUMBNAIL_FAIL,
  payload: err,
});
